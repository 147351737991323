<template>
  <v-card>
    <v-card-title class="text-h5 lighten-2">
      {{ title }}
    </v-card-title>
    <v-card-text class="mt-5 delete-confirm-content">
      {{ message }}
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <div>
        <v-btn
          class="mr-2"
          color="primary"
          outlined
          @click="deleteAction('cancel')"
        >
          {{ cancelButtonText }}
        </v-btn>
        <v-btn
          :loading="isDataSubmitting"
          color="primary"
          @click="deleteAction('confirmed')"
        >
          {{ confirmButtonText }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DeleteConfirmation',
  props: {
    title: {
      type: String,
      default: 'Are You Sure?'
    },
    message: {
      type: String,
      default: 'Are You Sure You Want To Delete? \n This operation is permanent and cannot be undone.'
    },

    confirmButtonText: {
      type: String,
      default: 'Delete'
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },

    loading: {
      type: Boolean,
      default: false,
    }

  },
  data: () => ({
    isDataSubmitting: false
  }),
  watch: {
    loading: {
      handler(nv) {
        this.isDataSubmitting = nv;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    deleteAction(type) {
      this.$emit('action', type);
    }
  },
};
</script>
<style scoped lang="scss">
.v-card__title {
  background: #012169 !important;
  color: #fff !important;
}
.delete-confirm-content {
  font-size: 15px;
  color: #0d0925;
}
</style>
