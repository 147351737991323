import { isEmail, isNumber, isValidUkPostCode, sameAs, validName } from '@/utils/helper';

export const houseNumberValidationRules = () => ([
  (v) => !!v || 'House No. is required',
  (v) => (!!v && v.trim().length > 0) || 'House No. should be at least 1 character long',
  (v) => (!!v && v.length < 11) || 'House No. should not be greater than 10 characters'
]);

export const roadNumberValidationRules = () => ([
  (v) => !!v || 'Road no. is required',
  (v) => (!!v && v.trim().length > 0) || 'Road no. should be at least 1 character long',
  (v) => (!!v && v.length < 15) || 'Road no. should not be more than 15 characters'
]);

export const cityValidationRules = () => ([
  (v) => !!v || 'City name is required',
  (v) => (!!v && v.trim().length >= 3) || 'City name should be at least 3 characters long',
  (v) => (!!v && v.length < 35) || 'City name should not be more than 35 characters',
]);

export const countyValidationRules = () => ([
  (v) => !!v || 'Country name is required',
  (v) => (!!v && v.trim().length >= 3) || 'Country name should be at least 3 characters long',
  (v) => (!!v && v.length < 35) || 'Country name should not be more than 35 characters'
]);

export const postCodeValidationRules = () => ([
  (v) => !!v || 'Postcode is required',
  (v) => (!!v && v.trim().length >= 3) || 'Postcode should be at least 3 characters long',
  (v) => (!!v && v.length < 20) || 'Postcode should not be more than 20 characters',
  // (v) => (!!v && isValidUkPostCode(v)) || 'This post code is not a valid UK post code'
]);

export const addressTypeValidation = () => ([
  (v) => !!v || 'Address type is required',
  (v) => (!!v && (v === 'Work' || v === 'Home')) || 'Invalid address type'
]);

export const userFirstNameRule = () => ([
  (v) => !!v || 'First Name is required',
  (v) => (!!v && validName(v))|| 'First name is invalid',
  (v) => (v && v.length < 20) || 'First name must not be greater than 20 characters',
]);

export const userLastNameRule = () => ([
  (v) => !!v || 'Last Name is required',
  (v) => (!!v && validName(v))|| 'Last name is invalid',
  (v) => (v && v.length < 20) || 'Last name must not be greater than 20 characters',
]);

export const userEmailRule = () => ([
  (v) => !!v || 'Email is required',
  (v) => (!!v && isEmail(v)) || 'Email must be valid',
]);

export const userPhoneNumberRule = () => ([
  (v) => !!v || 'Phone number is required',
  (v) => (!!v && v.length >= 8) || 'Phone number must be at least 8 or more character',
  (v) => (!!v && isNumber(v)) || 'Phone number must be number'
]);

export const userPasswordRule = (value, confirming = false) => {

  let rules = [
    (v) => !!v || 'Password is required',
    (v) => (!!v && v.length > 7) || 'Password must be 8 or more character',
    (v) => (!!v && v.length <= 50) || 'Password must be 50 or less character',
  ];


  if(confirming){
    rules.push((v) => (!!v && sameAs(value, v)) || 'Password and confirm password must be same');
  }

  return rules;
};

export const userNameRule = () => ([
  (v) => !!v || 'Name is required',
  (v) => (!!v && validName(v))|| 'name is invalid',
]);

export const requiredRule = (fieldName = 'field') => ([
  (v) => !!v || fieldName + ' is required',
]);

export const minRule = (min = 1, fieldName = 'field') => ([
  (v) => (!!v && v.trim().length >= min) || fieldName + ' must be at least ' + min + ' characters',
]);

export const maxRule = (max = 50, fieldName = 'field') => ([
  (v) => (!!v && v.length <= max) || fieldName + ' must not be greater than ' + max + ' characters',
]);
