<template>
  <v-card width="100%">
    <v-card-title class="address-card text-white d-flex justify-space-between">
      <span>{{ singleAddress.type }}</span>
      <v-menu
        bottom
        right
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title
              class="pointer-cursor"
              @click="selectAddress('update')"
            >
              <v-icon> mdi-pencil</v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              class="pointer-cursor"
              @click="selectAddress('delete')"
            >
              <v-icon> mdi-delete</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text class="mt-3">
      <div class="d-flex flex-column">
        <div class="d-flex justify-space-between">
          <span class="key">House :</span>
          <span class="value"> {{ singleAddress.house_no }} </span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="key">Road</span>
          <span class="value">{{ singleAddress.road }}</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="key">City :</span>
          <span class="value">{{ singleAddress.city }}</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="key">Country :</span>
          <span class="value">{{ singleAddress.county }}</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="key">Postcode :</span>
          <span class="value">{{ singleAddress.post_code }}</span>
        </div>

        <div class="d-flex justify-space-between flex-wrap">
          <span class="key">Address Note :</span>
          <span class="value">&nbsp;{{ singleAddress.note }}</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-switch
        v-if="!selectable"
        v-model="singleAddress.is_default"
        :false-value="0"
        :true-value="1"
        color="secondary"
        label="Default Address"
        @change="updateDefault(singleAddress.id)"
      />
      <slot :data="singleAddress" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { toggleDefaultAddress } from '@/api/user';
import Toast from '@/utils/Toast';

export default {
  name: 'AddressCard',
  props: {
    address: {
      type: Object,
      default: () => {}
    },
    selectable: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    singleAddress: {},
    selectedForDelivery: false
  }),
  watch: {
    address: {
      handler(data) {
        this.singleAddress = data;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {

    selectAddress(type, value = null) {
      this.$emit('addressSelected', { type: type, data: this.singleAddress, value: value });
    },

    async updateDefault(id){
      const response = await toggleDefaultAddress(id);
      await this.$store.dispatch('app/fetchLoggedInUser');
      await Toast.success(response.data.message);
    },
  }
};
</script>

<style lang='scss' scoped>
.pointer-cursor {
  cursor: pointer;
}
.address-card {
  background: #012169;
  color: #fff;
  button {
    span {
      i {
        color: #ffffff !important;
      }
    }

  }
}
</style>
