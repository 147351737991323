<template>
  <v-form
    ref="addressForm"
    v-model="isValid"
    @submit.prevent="handleSubmit()"
  >
    <v-card width="100%">
      <v-card-title class="text-h5 address-card">
        {{ titleText }}
      </v-card-title>
      <v-card-text class="mt-3 py-4">
        <v-text-field
          v-model.trim="userAddress.house_no"
          :error-messages="errors.house_no"
          :rules="validationRules.house_no"
          outlined
          placeholder="House no."
          label="House no. *"
          color="secondary"
          required
        />

        <v-text-field
          v-model.trim="userAddress.road"
          :error-messages="errors.road"
          :rules="validationRules.road"
          outlined
          placeholder="Road"
          label="Road *"
          color="secondary"
        />

        <v-text-field
          v-model.trim="userAddress.city"
          :error-messages="errors.city"
          :rules="validationRules.city"
          outlined
          placeholder="City"
          label="City *"
          color="secondary"
        />

        <v-text-field
          v-model.trim="userAddress.county"
          :error-messages="errors.county"
          :rules="validationRules.county"
          outlined
          placeholder="County"
          label="County *"
          color="secondary"
        />

        <v-text-field
          v-model.trim="userAddress.post_code"
          :error-messages="errors.post_code"
          :rules="validationRules.post_code"
          outlined
          placeholder="Postcode"
          label="Postcode *"
          color="secondary"
        />

        <v-text-field
          v-model.trim="userAddress.note"
          :error-messages="errors.note"
          outlined
          placeholder="Special Note"
          label="Special Note"
          color="secondary"
        />

        <v-select
          v-model="userAddress.type"
          :error-messages="errors.type"
          :items="['Work', 'Home']"
          :rules="validationRules.type"
          label="Address Type *"
          color="secondary"
          outlined
        />

        <v-switch
          v-model="userAddress.is_default"
          :error-messages="errors.is_default"
          :false-value="0"
          :true-value="1"
          label="Make Default Address"
          color="secondary"
          outlined
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          outlined
          @click="updateNotify(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!isValid"
          :loading="formSubmitting"
          color="secondary"
          type="submit"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { userAddressCreate, userAddressUpdate } from '@/api/user';
import Toast from '@/utils/Toast';
import {
  houseNumberValidationRules, roadNumberValidationRules,
  cityValidationRules, countyValidationRules,
  postCodeValidationRules, addressTypeValidation,
} from '@/utils/Validation/validationRules';
import ErrorHelper from '@/utils/ErrorHelper';

export default {
  name: 'UserAddressForm',
  props: {

    mode: {
      type: String,
      default: 'create'
    },

    userData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {

      isUpdating: false,
      isValid: false,
      formSubmitting: false,

      userAddress: {
        id: '',
        house_no: '',
        road: '',
        city: '',
        county: '',
        post_code: '',
        note: '',
        type: '',
        is_default: false,
      },

      errors: {
        id: '',
        house_no: '',
        road: '',
        city: '',
        county: '',
        post_code: '',
        note: '',
        type: '',
        is_default: '',
      },

      validationRules: {
        house_no: houseNumberValidationRules(),
        road: roadNumberValidationRules(),
        city: cityValidationRules(),
        county: countyValidationRules(),
        post_code: postCodeValidationRules(),
        type: addressTypeValidation()

      },
    };

  },
  computed: {
    titleText() {
      return this.isUpdating ? 'Update Address' : 'Add New Address';
    },
    buttonText() {
      return this.isUpdating ? 'Save' : 'Add';
    },
  },
  watch: {
    userData: {
      handler(data) {

        let formData = {
          id: '',
          house_no: '',
          road: '',
          city: '',
          county: '',
          post_code: '',
          node: '',
          type: '',
          is_default: false,
        };

        if(this.mode === 'update') {
          this.isUpdating = true;

          formData = {
            id: data.id,
            house_no: data.house_no,
            road: data.road,
            city: data.city,
            county: data.county,
            post_code: data.post_code,
            node: data.note,
            type: data.type,
            is_default: data.is_default,
          };
          this.userAddress = formData;
          this.$refs.addressForm?.validate();
          return;
        }

        this.userAddress = formData;
      },

      immediate: true,
      deep: true
    },
    userAddress: {
      handler(data) {
        for(let key in data) {
          if(this.errors[key]) {
            this.errors[key] = '';
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    async handleSubmit() {
      this.formSubmitting = true;
      await this.saveAddress();
      this.formSubmitting = false;
    },


    async saveAddress() {

      let data = {
        _method: this.isUpdating ? 'PATCH' : 'POST',
        house_no: this.userAddress.house_no,
        road: this.userAddress.road,
        city: this.userAddress.city,
        county: this.userAddress.county,
        post_code: this.userAddress.post_code,
        note: this.userAddress.note,
        type: this.userAddress.type,
        is_default: this.userAddress.is_default,
      };

      try {

        const response = this.isUpdating
          ? await userAddressUpdate(data, this.userAddress.id)
          : await userAddressCreate(data)
        ;
        await this.$store.dispatch('app/fetchLoggedInUser');
        await Toast.success(response.data.message);
        await this.updateNotify(response);
      } catch(e) {

        ErrorHelper.mapServerError(e, (err, ex) => {
          this.errors.house_no = ex(err.house_no);
          this.errors.road = ex(err.road);
          this.errors.city = ex(err.city);
          this.errors.county = ex(err.county);
          this.errors.post_code = ex(err.post_code);
          this.errors.note = ex(err.note);
          this.errors.type = ex(err.type);
          this.errors.is_default = ex(err.is_default);
        });

        Toast.error(e.message);
      }
    },
    updateNotify(data) {
      this.isUpdating = false;
      this.$emit('updated', data);
    }
  },
};
</script>

<style scoped lang="scss">
.address-card {
  background: #012169;
  color: #fff;
  button {
    span {
      i {
        color: #ffffff !important;
      }
    }

  }
}
</style>
