<template>
  <div>
    <v-row>
      <v-col
        v-for="(address, id) in userAddress"
        :key="'address'+id"
        cols="12"
        lg="4"
        xl="3"
      >
        <AddressCard
          :address="address"
          @addressSelected="selectAddress($event.data, $event.type)"
        />
      </v-col>
      <v-col v-if="!userAddress.length">
        <v-sheet>
          <v-card-title>No Address</v-card-title>
        </v-sheet>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        persistent
        width="600"
        scrollable
      >
        <UserAddressForm
          :mode="formMode"
          :user-data="selectedAddress"
          @updated="formUpdated"
        />
      </v-dialog>
      <v-dialog
        v-model="deleteDialog"
        persistent
        width="400px"
      >
        <delete-confirmation
          :loading="dataSubmitting"
          @action="deleteAction($event)"
        />
      </v-dialog>
    </div>

    <v-system-bar
      class="mt-5 pl-0"
      color="transparent"
      height="30"
    >
      <div
        class="d-flex"
        style="width: 100%"
      >
        <v-btn
          color="primary"
          @click="handleAddressBtnClick"
        >
          Add New Address
        </v-btn>
      </div>
    </v-system-bar>
  </div>
</template>

<script>
import UserAddressForm from '@/components/User/userAddressForm';
import { userAddressDelete } from '@/api/user';
import Toast from '@/utils/Toast';
import DeleteConfirmation from '@/components/Global/DeleteConfirmation';
import AddressCard from '@/components/User/AddressCard';
import { mapGetters } from 'vuex';

export default {
  name: 'Address',
  components: { AddressCard, DeleteConfirmation, UserAddressForm },
  data: () => ({
    userData: {},
    dialog: false,
    deleteDialog: false,
    selectedAddress: {},
    selectedForUpdate: false,
    dataSubmitting: false
  }),
  computed: {
    ...mapGetters({
      user: 'user/data'
    }),
    userAddress() {
      return this.userData?.address?.length ? this.userData.address : [];
    },
    formMode() {
      return this.selectedForUpdate ? 'update' : 'create';
    }
  },
  watch: {
    user: {
      handler(data) {
        this.userData = data;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async selectAddress(address, type) {

      this.selectedAddress = address;

      if (type === 'delete') {
        this.deleteDialog = true;
        return;
      }

      if (type === 'update') {
        this.selectedForUpdate = true;
        this.dialog = true;
      }
    },

    formUpdated() {
      this.selectedAddress = {};
      this.selectedForUpdate = false;
      this.dialog = false;
      this.deleteDialog = false;
    },

    async deleteAction(type) {
      this.dataSubmitting = true;
      if (type === 'confirmed') {
        try {
          let response = await userAddressDelete(this.selectedAddress.id);
          await Toast.info(response.data.message);
          await this.$store.dispatch('app/fetchLoggedInUser');
        } catch (e) {
          Toast.error(e.message);
        }
      }
      this.dataSubmitting = false;
      this.formUpdated();
    },

    handleAddressBtnClick() {

      if (this.userAddress.length >= 3) {
        Toast.info('Can not add more than 3 addresses');
        return;
      }

      this.dialog = !this.dialog;
    }
  }
};
</script>
<style lang="scss">
.table-title:after {
  background: #202e82 !important;
}
</style>
